import React, { Fragment, useState, useEffect } from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon, MailIcon } from '@heroicons/react/outline';
import data from '@data/template-4/navigations.json';
import iconSearchHeader from '@assets/icons/search-header.svg';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import Img from 'gatsby-image';
import { Button } from '@tradesorg-components/component-library-waas-templates.base-button';
import styles from './index.module.scss';
import { PhoneIcon } from '@heroicons/react/solid';
import StyledLogo from '@components/commons/styled-logo'


const ItemsMenuLeft = ({ navigations, setOpen, setOpenRequestMeeting }) => {
    return (
        <>
            <Popover.Group
                as="nav"
                className="flex xs:space-x-0 xl:space-y-0 xs:space-y-5 xl:space-x-10 xl:flex xs:grid grid-cols-1 w-full md-1:w-1/2 xl:w-auto "
            >
                {navigations &&
                    Array.isArray(navigations) &&
                    navigations.map((item) => {
                        if(item.show){
                            return (
                                <AnchorLink
                                    key={nanoid()}
                                    to={item.path}
                                    title={item.title}
                                    className="font-bold xs:font-16px md-1:font-22px not-italic tracking-normal
                                    text-left text-gray-600 leading-loose xl:leading-4 hover:text-gray-500 xl:font-14px
                                    whitespace-nowrap mx-0 xs:mt-0 xl:mx-13px "
                                    onClick={() => setOpen && setOpen(false)}
                                />
                            );
                        }

                    })}
                    <AnchorLink to="/#contact">
                        <Button
                            onClick={() => setOpen && setOpen(false)}
                            rounded
                            className="h-14 mt-6 text-white bg-blue-600 px-8 py-4 rounded text-sm font-semibold block md-1:hidden w-full"
                        >
                            Contact Us
                        </Button>
                </AnchorLink>
            </Popover.Group>
        </>
    );
};

const IconSearch = () => {
    return (
        <div className="mr-30px cursor-pointer">
            <img src={iconSearchHeader} className="w-15px h-15px" alt="icon-search" />
        </div>
    );
};


const ItemsMenuRight = ({ email, phone, singleLineAddress, setOpen, setOpenRequestMeeting }) => {


    return (
        <div className="hidden md-1:block border-l xl:border-0 md-1:flex items-center xs:justify-center xl:justify-start md-1:w-1/2 xl:w-auto" style={{borderColor: "#F4F5F7"}}>
            <div className="hidden xl:flex items-center">
            {/* <IconSearch /> */}
               <AnchorLink to="/#contact">
                <Button

                    rounded
                    className="text-white bg-blue px-33px py-18px rounded-md text-sm font-bold font-14px"
                >
	                Contact us
                </Button>
                </AnchorLink>
            </div>
            <div className="grid xl:hidden items-center pl-10 w-full">
                <div className="items-center text-left">
                    <div className="text-xs leading-7 h-6 text-gray-400">GET IN TOUCH</div>
                    <div className="font-semibold leading-7 h-6 text-2xl mb-7">Contact Us</div>

	                {phone && <p className="mt-3 text-normal not-italic tracking-normal leading-4 mb-4 flex gap-13px">
		                <svg className="w-15px" width="15" height="15" viewBox="0 0 15 15" fill="none"
		                     xmlns="http://www.w3.org/2000/svg">
			                <path
				                d="M14.4287 10.986C14.1722 10.785 13.8617 10.6575 13.5872 10.53C12.6902 10.1655 11.7752 9.9285 10.9892 9.7455C10.7147 9.6915 10.3307 9.582 9.94671 9.7275C9.65421 9.837 9.47121 10.056 9.30621 10.257C9.25221 10.3305 9.17871 10.4025 9.12321 10.476C8.97771 10.6215 8.57421 10.548 8.08071 10.275C7.51371 9.984 6.83721 9.4545 6.19671 8.8155C5.55621 8.1765 5.02521 7.5015 4.73421 6.936C4.45971 6.444 4.38621 6.0435 4.53171 5.8965C4.58721 5.8425 4.66071 5.787 4.75221 5.7135C4.95321 5.55 5.17221 5.367 5.28171 5.076C5.42871 4.692 5.31921 4.2915 5.26371 4.0365C5.08071 3.252 4.84371 2.3385 4.47771 1.446C4.36671 1.1535 4.22121 0.860999 4.02021 0.605999C3.76371 0.296999 3.45321 0.0779991 3.10521 0.0224991C2.62971 -0.0690012 2.09871 0.114 1.56921 0.57C1.16721 0.9165 0.81921 1.335 0.56271 1.8285C0.30621 2.3205 0.123211 2.814 0.0512106 3.342C-0.133289 4.5825 0.17871 5.952 1.02021 7.539C1.73421 8.907 2.64771 10.1475 3.72771 11.2605V11.2785C4.82571 12.354 6.08721 13.2675 7.44021 13.9785C8.73921 14.6535 9.89271 15 10.9532 15C11.1902 15 11.4287 14.982 11.6477 14.9445C12.1787 14.8725 12.6902 14.6895 13.1657 14.4345C13.6427 14.178 14.0627 13.8315 14.4287 13.431C14.8862 12.921 15.0692 12.3915 14.9777 11.898C14.9597 11.5515 14.7392 11.223 14.4287 10.986Z"
				                fill="#0056DD"/>
		                </svg>
		                {phone}
	                </p>}

	                {email && <p className="mt-3 text-normal not-italic tracking-normal leading-4 mb-4 flex gap-13px">
		                <svg className="w-15px" width="18" height="12" viewBox="0 0 18 12" fill="none"
		                     xmlns="http://www.w3.org/2000/svg">
			                <path d="M0.692383 0L9.00007 7.8L17.3078 0H0.692383Z" fill="#0056DD"/>
			                <path d="M0 0.599609V11.3996L5.53846 5.84961L0 0.599609Z" fill="#0056DD"/>
			                <path d="M18.0004 0.599609L12.4619 5.69961L18.0004 11.2496V0.599609Z" fill="#0056DD"/>
			                <path
				                d="M0.553711 12.0008L6.09217 6.45078L8.99986 9.00078L11.7691 6.30078L17.3076 12.0008H0.553711Z"
				                fill="#0056DD"/>
		                </svg>
		                {email}
	                </p>}

	                {singleLineAddress && <p className="mt-3 text-normal not-italic tracking-normal leading-4 flex gap-13px">
		                <svg className="w-15px" width="13" height="19" viewBox="0 0 13 19" fill="none"
		                     xmlns="http://www.w3.org/2000/svg">
			                <path fillRule="evenodd" clipRule="evenodd"
			                      d="M6.5 0C2.91262 0 0 2.95279 0 6.58966C0 7.64902 0.544143 9.14205 1.33931 10.7013C3.09152 14.1367 6.01064 17.9568 6.01064 17.9568C6.12795 18.1103 6.30871 18.2 6.5 18.2C6.69129 18.2 6.87205 18.1103 6.98936 17.9568C6.98936 17.9568 9.90848 14.1367 11.6607 10.7013C12.4559 9.14205 13 7.64902 13 6.58966C13 2.95279 10.0874 0 6.5 0ZM6.5 3.76552C4.96259 3.76552 3.71429 5.03104 3.71429 6.58966C3.71429 8.14827 4.96259 9.41379 6.5 9.41379C8.0374 9.41379 9.28571 8.14827 9.28571 6.58966C9.28571 5.03104 8.0374 3.76552 6.5 3.76552Z"
			                      fill="#0056DD"/>
		                </svg>
		                {singleLineAddress}
	                </p>}
                </div>
                <AnchorLink to="/#contact">
                <Button

                    rounded
                    className="h-14 mt-7 text-white bg-blue-600 px-8 py-4 rounded text-sm font-semibold md-1:w-200px"
                >
                    Contact Us
                </Button>
                </AnchorLink>
            </div>
        </div>
    );
};

const NavbarComponent = ({ shadow = true, setOpenRequestMeeting, data: strapiData, ...props }) => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const imageSrc = strapiData.logo?.url ? strapiData.logo.url : ''

    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    const [scrolled,setScrolled] = useState(false);

    const handleScroll = () => {
        if(typeof window != "undefined"){
            const offset = window.scrollY;
            if(offset > 150 ) {
                setScrolled(true);
            }
            else {
                setScrolled(false);
            }
        }
    }

    useEffect(() => {
        if(typeof window != "undefined"){
            window.addEventListener('scroll', handleScroll)
        }
    })

    return (
        <Popover className={`navbar bg-white ${scrolled && `scrolled` }`} {...props}>
            <>
                <div
                    className={`absolute  shadow-md inset-0 z-10 pointer-events-none`}
                    aria-hidden="true"
                />
                <div className="relative">
                    <div className="flex items-center justify-between h-auto mx-auto xl:mx-0 xs:px-20px xs:py-15px md-1:px-8
                    xl:py-6 xs:py-4 md:justify-between h-65px xl:h-103px">
                        {/* <Logo logo={strapiData.logo} /> */}
                        <StyledLogo logo={strapiData.logo} />

                        <div className=" xl:hidden">
                            <div className="-my-2 flex">
                                {strapiData?.phone ? <a href={`tel:${strapiData?.phone}`}>
                                    <PhoneIcon className="h-8 w-8 mr-2 p-0.5" />
                                </a>: <></>}

                                {strapiData?.email ? <a href={`mailto:${strapiData?.email}`}>
                                    <MailIcon className="h-8 w-8 mr-2" />
                                </a>: <></>}
                            </div>
                        </div>

                        <div className="flex justify-end xl:hidden">
                            <div onClick={() => setOpen(true)} className="-my-2 -mr-2 xl:hidden">
                                <Popover.Button
                                    className={`inline-flex items-center justify-center text-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-inset`}
                                >
                                    <span className="sr-only">Open menu</span>
                                    <MenuIcon
                                        className="w-35px h-35px text-blue-500 py-6px"
                                        aria-hidden="true"
                                    />
                                </Popover.Button>
                            </div>
                        </div>

                        <div className={`hidden mx-auto xl:flex-1 xl:flex xl:items-center xl:justify-between ${styles.navBarHeader}`}>
                            <ItemsMenuLeft
                                navigations={props.navigations}
                                setOpenRequestMeeting={setOpenRequestMeeting}
                            />
                            <ItemsMenuRight
                                {...strapiData}
                                data={props}
                                setOpen={setOpen}
                                setOpenRequestMeeting={setOpenRequestMeeting}
                            />
                        </div>
                    </div>
                </div>

                <Transition
                    show={open}
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        static
                        className="absolute h-screen md-1:h-auto top-0 inset-x-0 transition transform origin-top-right xl:hidden z-20"
                    >
                        <div className="h-full md-1:h-auto rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white xl:divide-y-2 xl:divide-gray-50">
                            <div className="px-5 md-1:px-10 py-6 xl:px-5 space-y-6">
                                <div className="flex items-center justify-between">
                                    <StyledLogo logo={strapiData.logo} />
                                    <div onClick={() => setOpen(false)} className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            {/* <InputTemplate
                                classNames={{ wrapper: 'mx-5 md-1:mx-10' }}
                                rounded={true}
                                name="search"
                                id="search"
                                placeholder="Search"
                                onChange={handleChange}
                                value={search}
                                RightComponent={
                                    <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.8779 18.6996L14.0681 12.8897C15.17 11.5293 15.8334 9.79975 15.8334 7.9167C15.8334 3.55145 12.2819 0 7.91666 0C3.55141 0 0 3.55145 0 7.9167C0 12.2819 3.55145 15.8334 7.9167 15.8334C9.79975 15.8334 11.5293 15.17 12.8897 14.0681L18.6996 19.878C18.8623 20.0407 19.1261 20.0407 19.2889 19.878L19.878 19.2888C20.0407 19.1261 20.0407 18.8623 19.8779 18.6996ZM7.9167 14.1667C4.47024 14.1667 1.66668 11.3631 1.66668 7.9167C1.66668 4.47024 4.47024 1.66668 7.9167 1.66668C11.3631 1.66668 14.1667 4.47024 14.1667 7.9167C14.1667 11.3631 11.3631 14.1667 7.9167 14.1667Z"
                                            fill="#6e4bd9"
                                        />
                                    </svg>
                                }
                            /> */}
                            <div className="pt-11 pb-14 py-10px px-5 md-1:px-10 xl:py-6 xl:px-5 space-y-6 md-1:pt-44px md-1:pb-55px">
                                <div className="flex justify-between">
                                    <ItemsMenuLeft
                                        navigations={props.navigations}
                                        setOpen={setOpen}
                                        setOpenRequestMeeting={setOpenRequestMeeting}
                                    />
                                    {/*
                                    <div className={`hidden xl:block ${styles.rectangle}`} />
                                    <ItemsMenuRight
                                        {...strapiData}

                                    /> */}
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>
        </Popover>
    );
};

export default NavbarComponent;
