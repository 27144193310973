import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import data from '@data/template-4/navigations.json';
import iconFacebook from '@assets/icons/template-4/facebook.svg';
import iconInstagram from '@assets/icons/template-4/instagram.svg';
import iconPinterest from '@assets/icons/template-4/pinterest.svg';
import iconHouse from '@assets/icons/template-4/house.svg';
import iconHouzz from '@assets/icons/template-4/houzz.svg';
import iconLinkedin from '@assets/icons/template-4/linkedin.svg'
import { nanoid } from 'nanoid';
import './index.scss';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';

const FooterComponent = ({data : strapiData, services, ...props}) => {
	const footerNavigations = data.footer;
	const { email, phone, singleLineAddress, socialProfiles } = strapiData;

	const handleRenderIcon = (key) => {
        if (key.includes('facebook')) {
            return iconFacebook;
        }
        if (key.includes('instagram')) {
            return iconInstagram;
        }
        if (key.includes('house')) {
            return iconHouse;
        }
        if (key.includes('houzz')) {
            return iconHouzz;
        }
        if (key.includes('pinterest')) {
            return iconPinterest;
        }

        return iconLinkedin;
    };


	return (
		<footer className="mt-20px FooterComponent w-full overflow-hidden bg-black pb-50px pt-30px px-20px xl:py-70px xl:px-98px" aria-labelledby="footerHeading">
			<h2 id="footerHeading" className="sr-only">
				{ props.footerText|| `Footer` }
			</h2>
			<div className={ ''}>
				<div className="md-1:grid md-1:grid-cols-4">
					<div className="mt-20px md-1:mt-0px grid grid-cols-1 gap-8 xl:mt-0">
						<div>
							<div>
								<div className="text-xs font-bold leading-6 h-6 text-left">{footerNavigations.col1.title}</div>
								<ul className="mt-14px text-gray-700  text-gray-700 leading-6">
									{ services?.map(({ name }, index) => (
										<li key={index} className="text-left">
											<AnchorLink
												rel="noopener"
												to={`/#service-${index+1}`}
												title={name}
												className="text-sm not-italic font-normal tracking-normal text-left leading-6"
											/>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
					<div className="mt-20px md-1:mt-0px grid grid-cols-1 gap-8 xl:mt-0">
						{/* <div>
							<div>
								<div className="text-xs font-bold leading-6 h-6 text-left"></div>
								<ul className="mt-14px text-gray-700 leading-6">
									{footerNavigations?.col2.content.map((item) => (
										<li className="text-left" key={nanoid()}>
											<AnchorLink
												rel="noopener"
												to={item.link}
												title={item.title}
												className="text-sm not-italic font-normal tracking-normal text-left leading-6"
											/>
										</li>
									))}
								</ul>
							</div>
						</div> */}
					</div>

					<div className="mt-20px md-1:mt-0px  grid-cols-2 xl:mt-0">
						<div className="grid grid-cols-1 text-gray-700">
							<div className="items-center text-left">
								<div className="text-xs font-bold leading-6 h-6 text-left">{footerNavigations.col3?.title}</div>
								<a href={`tel:${phone}`}>
								<p className="mt-14px text-sm not-italic tracking-normal text-left leading-4">
								{ formatPhoneNumber( phone) }
								</p>
								</a>
								<a href={`mailto:${email}`}>
								<p className="mt-3 text-sm not-italic tracking-normal text-left leading-4">
									{ email }
								</p>
								</a>
								<p className="mt-3 text-sm not-italic tracking-normal text-left leading-4">
									{ singleLineAddress }
								</p>
							</div>
						</div>
					</div>

					<div className="mt-20px md-1:mt-0px grid-cols-1 xl:mt-0 justify-self-end">
					{!!socialProfiles.length && <div className="items-center">
							<div className="text-xs font-bold leading-6 h-6 text-left">{footerNavigations.col4?.title}</div>
							<div className="flex mt-5 cursor-pointer space-x-5  xs:justify-center lg:justify-start">
								{ socialProfiles?.map((item) => {
									return (
										<a href={item.url} key={nanoid()} target="_blank"  rel="noopener">
										<img
											key={nanoid()}
											src={handleRenderIcon(item.url)}
											alt={`icon-${item.url}`}
										/>
										</a>
									);
								})}
							</div>
						</div>}

						<div className="mx-auto mt-50px md-1:mt-21px xl:mt-54px flex-1 flex md-1:items-center md-1:justify-between">
							<div className="item-copyright-left grid grid-cols-2 xl:grid-cols-2 md-1:grid-cols-1 xl:block">
								{footerNavigations?.coppyRight.content.map((item) => {
									return (
										<a
											rel="noopener"
											className="mr-5 text-sm not-italic font-normal tracking-normal text-gray-600
                                        underline md-1:text-left leading-6 md-1:mt-0 xs:mt-5 font-11px text-left"
											key={nanoid()}
											href={item.link}
											target="_blank"
										>
											{item.title}
										</a>
									);
								})}

							</div>
						</div>
						<div className="item-copyright-right grid grid-cols-1 xl:block">
							{footerNavigations?.coppyRight?.power && (
								<p className="text-sm not-italic font-normal tracking-normal font-11px
                                text-left leading-6 md-1:mt-0 xs:mt-10px text-sm text-gray-300">
									{footerNavigations.coppyRight.power} <a href="https://www.trades.org" target="_blank"  rel="noopener">trades.org</a>
								</p>
							)}
						</div>
					</div>
				</div>

			</div>
		</footer>
	);
};

export default FooterComponent;
