import React, { useState } from 'react'
import NavbarComponent from '../navbar'
import SEO from '@components/commons/seo'
import FooterComponent from '../footer'
import RequestAQuoteModal from '../../../template-2/request-a-quote'

const Layout = ({ children, seoData, businessData, faqs, ...rest }) => {
    const [openRequestQuote, setOpenRequestQuote] = useState(false)
    const headerNavigations = [
        {
            title: 'Home',
            path: '/',
            show: true,

        },
        {
            title: 'Services',
            path: '/#services',
            show: businessData?.highlightedServices && true,
        },
        {
            title: 'Get a Project Loan',
            path: '/#',
            show: false,
        },
        {
            title: 'Make a Payment',
            path: '/#',
            show: false,
        },
        {
            title: 'FAQs',
            path: '/faq',
            show: !!faqs?.faqs?.length && true,
        },
        {
            title: 'Team',
            path: '/#team',
            show: !!businessData?.team?.length && true,
        },
    ]

    return (
        <div className="overflow-hidden template-2">
            <SEO data={seoData}/>
            <NavbarComponent {...rest} setOpenRequestQuote={setOpenRequestQuote} navigations={headerNavigations}
                             data={businessData}/>
            <RequestAQuoteModal setOpenRequestQuote={setOpenRequestQuote} openRequestQuote={openRequestQuote}/>
            <main>
                <div className="max-w-screen-2xl mx-auto pb-24">
                    {children}
                </div>
            </main>
            <FooterComponent {...rest} data={businessData} navigations={headerNavigations}/>
        </div>
    )
}
export default Layout
