import React from 'react';
import FooterComponent from '../footer';
import NavbarComponent from '../navbar';
import SEO from '../seo'

const Layout = ({ children ,data, seoData, faqs, services, projects, testimonials, ...rest }) => {

   const headerNavigations = [
        {
            title: "Home",
            path: "/",
            show : true

        },
        {
            title: "Contact Us",
            path: "/#contact",
            show : data.address?.longitude && true
        },

        {
            title: "Services",
            path: "/#services",
            show : services?.highlightedServices && true
        },
        {
            title: "Projects",
            path: "/#projects",
            show : !!projects?.completedProjects?.length && true
        },
        {
            title: "Testimonials",
            path: "/#testimonials",
            show : !!testimonials?.testimonials?.length && true
        },
        {
            title: "FAQ",
            path: "/#faq",
            show : !!faqs?.faqs?.length && true
        }
    ]

    return (
        <div className="overflow-hidden">
            <SEO data={seoData}/>
            <NavbarComponent {...rest} logo={data.logo} navigations={headerNavigations}/>
            <main>{children}</main>
            <FooterComponent data={data} navigations={headerNavigations}/>
        </div>
    );
};
export default Layout;
