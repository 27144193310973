import React from 'react'
import { graphql, Link } from 'gatsby'
import { button } from './index.module.scss'
import Layout1 from '../components/commons/layout'
import Layout2 from '../components/commons/template-2/layout'
import Layout3 from '../components/commons/template-3/layout'
import Layout4 from '../components/commons/template-4/layout'

const NotFoundPage = ({
    data: {
        AbstractionDataStore: { getWebsite, getUser, getBusiness },
        DomainRequisition: { getDomainRequisitionsByBusinessId: domain },
    },
}) => {

    const appSyncData = {
        business: {
            ...getBusiness,
            ourProcess: {
                caption: getBusiness?.ourProcess?.caption,
                processSteps: getBusiness?.processSteps?.items,
            },
            displayName: getBusiness?.displayName,
            shortDescription: getBusiness?.shortDescription,
            serviceArea: getBusiness?.serviceArea,
            socialProfiles: getBusiness?.links?.items
              ? getBusiness?.links?.items
              : [],
            feedbacks: getBusiness?.feedbacks.items,
            phone: getBusiness?.phones?.items.length
              ? getBusiness?.phones?.items[0]?.phoneNumber
              : getUser?.phone,
            email: getBusiness?.emails?.items.length
              ? getBusiness?.emails?.items[0]?.email
              : getUser?.email,
        },
        website: {
            ...getWebsite,
            highlightedFeatures: getWebsite?.highlightedFeatures?.items,
            highlightedServices: getWebsite?.highlightedServices?.items,
            faqs: getWebsite?.faqs?.items,
            testimonials: getWebsite?.testimonials?.items,
            completedProjects: getWebsite?.completedProjects?.items,
            guarantees: {
                caption: getWebsite?.guarantee?.caption,
                descriptions: getWebsite?.guaranteeDescriptions?.items,
            },
        },
    }

    const { business, website } = appSyncData
    const businessData = {
        ...business,
        address: {
            address: business.location?.streetAddress,
            longitude: business.location?.longitude,
            latitude: business.location?.latitude,
            singleLineAddress: business.location?.singleLineAddress,
            state: business.location?.state,
        },
    }
    const seoData = {
        title: businessData.displayName,
        description: businessData.shortDescription,
        domain: domain?.[0].domainName,
        logo: businessData?.logoImage?.url ?? null,
        previewImage: businessData?.coverImage?.url ?? null,
    }
    const template = process.env.GATSBY_TEMPLATE
    const layoutsMap = {
        1: {
            component: Layout1,
            props: {
                style: { background: '#D0ECF5' },
                data: {
                    logo: null,
                    ...businessData,
                },
                seoData,
                faqs: { faqs: website?.faqs ? website.faqs : [] },
                services: {
                    slogan: '',
                    titleLeft: 'Services',
                    titleRight: 'We provide the following services',
                    services: website?.highlightedServices
                      ? website.highlightedServices
                      : [],
                },
                projects: {
                    slogan: '',
                    titleLeft: 'Completed Projects',
                    titleRight: '',
                    projects: website?.completedProjects,
                },
                testimonials: {
                    sloganText: '',
                    titleLeft: 'Testimonials',
                    titleRight: 'Our clients are very happy with our work',
                    testimonials: website?.testimonials
                      ? website.testimonials
                      : [],
                },
            },
        },
        2: {
            component: Layout2,
            props: {
                style: { background: '#f9f9fc' },
                businessData: {
                    phone: businessData.phone,
                    email: businessData.email,
                    singleLineAddress: businessData.address?.singleLineAddress,
                    socialProfiles: businessData.socialProfiles,
                    services: businessData.highlightedServices,
                    ...businessData,
                },
                seoData,
                faqs: {
                    heading: 'Frequently Asked Questions',
                    faqs: website?.faqs?.items ? website.faqs.items : [],
                },
            },
        },
        3: {
            component: Layout3,
            props: {
                businessData: {
                    logo: businessData.logoImage?.localFile?.childCloudinaryAsset?.fluid ??
                      null,
                    phone: businessData.phone,
                    email: businessData.email,
                    singleLineAddress: businessData.address?.singleLineAddress,
                    socialProfiles: businessData.socialProfiles,
                    services: website?.highlightedServices?.items
                      ? website?.highlightedServices?.items
                      : [],
                    ...businessData,
                },
                seoData,
                faqs: {
                    heading: '<div>Frequently <span class="text-blue-500">Asked </span> Questions</div>',
                    faqs: website?.faqs?.items ? website.faqs.items : [],
                },
            },
        },
        4: {
            component: Layout4,
            props: {
                businessData: {
                    logo: businessData.logoImage?.localFile?.childCloudinaryAsset?.fluid ??
                      null,
                    phone: businessData.phone,
                    email: businessData.email,
                    singleLineAddress: businessData.address?.singleLineAddress,
                    socialProfiles: businessData.socialProfiles,
                    services: website?.highlightedServices?.items,
                    ...businessData,
                }, seoData, faqs: {
                    heading: 'Frequently Asked Questions',
                    text: '',
                    faqs: website?.faqs?.items ? website?.faqs?.items : [],
                }, services: businessData?.highlightedServices?.items ? businessData.highlightedServices.items: [],
            },
        },
        5: {
            component: Layout3,
            props: {
                businessData: {
                    logo: businessData.logoImage?.localFile?.childCloudinaryAsset?.fluid ??
                        null,
                    phone: businessData.phone,
                    email: businessData.email,
                    singleLineAddress: businessData.address?.singleLineAddress,
                    socialProfiles: businessData.socialProfiles,
                    services: website?.highlightedServices?.items
                        ? website?.highlightedServices?.items
                        : [],
                    ...businessData,
                },
                seoData,
                faqs: {
                    heading: '<div>Frequently <span class="text-blue-500">Asked </span> Questions</div>',
                    faqs: website?.faqs?.items ? website.faqs.items : [],
                },
            },
        }
    }

    const CurrentLayoutComponent = layoutsMap[parseInt(template)].component
    const currentLayoutProps = layoutsMap[parseInt(template)].props
    console.log({currentLayoutProps})
    return (
      <CurrentLayoutComponent {...currentLayoutProps}>
          <div className={'w-full my-24'}>
              <div
                className=" bg-center bg-cover flex grow items-center justify-center not-found-wrapper "
              >
                  <div
                    className="block md:grid content-not-found text-center w-2/3 lg:px-80 py-24 m-auto justify-center">
                      <div className="text-6xl font-bold text-gray-700">Oops!
                      </div>

                      <h2 className="text-2xl font-normal text-gray-600 mt-6">
                          We can’t seem to find that page. Try visiting the home
                          page.
                      </h2>
                      <div className="grid md:flex gap-4  justify-center">
                          <Link to="/">
                              <button
                                name="modal_toggle"
                                className={` border-0 outline-none text-white cursor-pointer w-full rounded-md text-lg mt-6 px-8 ${button}`}
                              >
                                  Home
                              </button>
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
      </CurrentLayoutComponent>
    )
}

export const query = graphql`
    query ($userId: ID!, $businessId: ID!, $websiteId: ID!, $businessIdString: String!) {
        DomainRequisition {
            getDomainRequisitionsByBusinessId(businessId: $businessIdString) {
                id
                domainName
                domainStatus
            }
        }
        AbstractionDataStore {
            getWebsite(id: $websiteId) {
                hideMyLocation
                headline
                subHeadline
                feature
                promotion {
                    description
                    value
                }
                guarantee {
                    caption
                }
                faqs {
                    items {
                        question
                        answer
                    }
                }
                highlightedFeatures {
                    items {
                        text
                        title
                    }
                }
                highlightedServices {
                    items {
                        description
                        name
                    }
                }
                completedProjects {
                    items {
                        description
                        name
                    }
                }
                guaranteeDescriptions {
                    items {
                        title
                        description
                    }
                }
                testimonials {
                    items {
                        description
                        name
                    }
                }
            }
            getUser(id: $userId) {
                email
                phone
            }
            getBusiness(id: $businessId) {
                id
                displayName
                shortDescription
                serviceArea
                location {
                    longitude
                    latitude
                    singleLineAddress
                    state
                    streetAddress
                }
                processSteps {
                    items {
                        stepNumber
                        stepDescription
                        stepTitle
                    }
                }
                ourProcess {
                    caption
                }
                feedbacks {
                    items {
                        review
                        fullName
                    }
                }
                links {
                    items {
                        url
                    }
                }
                emails {
                    items {
                        email
                    }
                }
                phones {
                    items {
                        phoneNumber
                    }
                }
            }
        }
    }
`

export default NotFoundPage
