import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import data from '@data/template-2/navigations.json';
import iconFacebook from '@assets/icons/template-2/facebook.svg';
import iconInstagram from '@assets/icons/template-2/instagram.svg';
import iconPinterest from '@assets/icons/template-2/pinterest.svg';
import iconLinkedin from '@assets/icons/template-2/linkedin.svg'
import iconHouse from '@assets/icons/template-2/house.svg';
import iconHouzz from '@assets/icons/template-2/houzz.svg';
import { nanoid } from 'nanoid';
import Img from 'gatsby-image'
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';

const FooterComponent = ({data : strapiData, navigations, ...props}) => {
    const footerNavigations = data.footer;
    const { logo, email, phone, singleLineAddress, socialProfiles, services } = strapiData;

    const handleRenderIcon = (key) => {
        if (key.includes('facebook')) {
            return iconFacebook;
        }
        if (key.includes('instagram')) {
            return iconInstagram;
        }
        if (key.includes('house')) {
            return iconHouse;
        }
        if (key.includes('houzz')) {
            return iconHouzz;
        }
        if (key.includes('pinterest')) {
            return iconPinterest;
        }

        return iconLinkedin;
    };

    return (
        <footer className="w-full overflow-hidden bg-white" aria-labelledby="footerHeading">
            <h2 id="footerHeading" className="sr-only">
                { props.footerText|| `Footer` }
            </h2>
            <div className={ props.classNames || `px-20px pt-50px pb-30px md-1:py-12 mx-auto sm:px-6 md-1:pt-0 md-1:pb-7 
            xl:py-16 md-1:px-10 xl:pr-98px xl:pl-98px`}>
                <div className="md-1:grid md-1:grid-cols-4 xl:grid-cols-5 md-1:gap-3">
                    <div className={`font-normal block md-1:hidden xl:block`}>
                        <div className={`font-extrabold text-left`}>
                            <Img className="lg:w-40  xs:w-20" fluid={logo} />
                        </div>
                    </div>
                    <div className="mt-20px md-1:mt-12 grid grid-cols-1 gap-8 xl:mt-0">
                        <div>
                            {footerNavigations && footerNavigations.col1 && (
                                <div>
                                    <div className="text-xs font-bold leading-6 h-6 text-left">{footerNavigations.col1.title}</div>
                                    <ul className="mt-14px text-gray-700  text-gray-700 leading-6">
                                        {services?.map((service, index) => (
                                            <li key={nanoid()} className="text-left">
                                                <AnchorLink
                                                    rel="noopener"
                                                    to={`/#service-${index+1}`}
                                                    title={service.title}
                                                    className="text-sm not-italic font-normal tracking-normal text-left leading-6"
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mt-20px md-1:mt-12 grid grid-cols-1 gap-8 xl:mt-0">
                        <div>
                            {footerNavigations && footerNavigations.col2 && (
                                <div>
                                    <div className="text-xs font-bold leading-6 h-6 text-left"></div>
                                    <ul className="mt-14px text-gray-700 leading-6">
                                        {footerNavigations.col2.content.map((item) => (
                                            <li className="text-left" key={nanoid()}>
                                                <AnchorLink
                                                    to={item.link}
                                                    title={item.title}
                                                    className="text-sm not-italic font-normal tracking-normal text-left leading-6"
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-20px md-1:mt-12  grid-cols-2 xl:mt-0">
                        <div className="grid grid-cols-1 text-gray-700">
                            <div className="items-center text-left">
                                <div className="text-xs font-bold leading-6 h-6 text-left">{footerNavigations.col3.title}</div>
                                <a href={`tel:${phone}`}>
                                <p className="mt-14px text-sm not-italic tracking-normal text-left leading-4">
                                    { formatPhoneNumber( phone) }
                                </p>
                                </a>
                                <a href={`mailto:${email}`}>
                                <p className="mt-3 text-sm not-italic tracking-normal text-left leading-4">
                                    { email }
                                </p>
                                </a>
                                <p className="mt-3 text-sm not-italic tracking-normal text-left leading-4">
                                    { singleLineAddress }
                                </p>
                            </div>
                        </div>
                    </div>
                    {!!socialProfiles.length &&  <div className="mt-20px md-1:mt-12 grid-cols-1 xl:mt-0 justify-self-end">
                            <div className="items-center">
                                <div className="text-xs font-bold leading-6 h-6 text-left">{footerNavigations.col4.title}</div>
                                <div className="flex mt-14px cursor-pointer justify-start">
                                    {socialProfiles?.map((item) => {
                                        return (
                                            <a href={item.url} target="_blank"  rel="noopener">
                                            <img
                                                key={nanoid()}
                                                src={handleRenderIcon(item.url)}
                                                alt={`icon-${item.url}`}
                                            />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>}


                    </div>
                    <div className="pt-5 mx-auto mt-31px md-1:mt-12  border-t border-gray-200 flex-1 md-1:flex md-1:items-center md-1:justify-between">
                        <div className="item-copyright-left grid grid-cols-1 xl:block">
                            {footerNavigations.coppyRight.content.map((item) => {
                                return (
                                    <a
                                    rel="noopener"
                                        className="mr-5 text-sm not-italic font-normal tracking-normal text-gray-600
                                        underline md-1:text-left leading-6 md-1:mt-0 xs:mt-5"
                                        key={nanoid()}
                                        href={item.link}
                                        target="_blank"
                                    >
                                        {item.title}
                                    </a>
                                );
                            })}
                        </div>
                        <div className="item-copyright-right grid grid-cols-1 xl:block">
                            {footerNavigations?.coppyRight?.power && (
                                <p className="text-sm not-italic font-normal tracking-normal
                                text-left leading-6 md-1:mt-0 xs:mt-10px text-sm text-gray-300">
                                    {footerNavigations.coppyRight.power} <a href="https://www.trades.org" target="_blank"  rel="noopener">trades.org</a>
                                </p>
                            )}
                        </div>
                    </div>
            </div>
        </footer>
    );
};
export default FooterComponent;
