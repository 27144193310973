import React, { useState } from 'react';
import NavbarComponent from '../navbar';
import SEO from '@components/commons/seo';
import FooterComponent from '../footer';
// import RequestAQuoteModal from "../../../template-4/request-a-quote";
import RequestMeetingModal from "../../../template-3/request-meeting";

const Layout = ({ children, seoData, businessData,faqs,...rest }) => {
	const [openRequestMeeting, setOpenRequestMeeting] = useState(false);
    const headerNavigations = [
        {
            title: "Home",
            path: "/",
            show : true
            
        },
        
        {
            title: "Services",
            path: "/#services",
            show :businessData?.highlightedServices && true
        }, 
        {
            title: "Get a Project Loan",
            path: "/#",
            show : false
        },
        {
            title: "Make a Payment",
            path: "/#",
            show : false
        },
        
        // {
        //     title: "Our Process",
        //     path: "/#our-process",
        //     show : !!businessData?.process?.length && true
        // },
        // {
        //     title: "Testimonials",
        //     path: "/#testimonials",
        //     show : !!businessData?.feedbacks?.length && true
        // },
        {
            title: "FAQs",
            path: "/faq",
            show : !!faqs?.faqs?.length && true
        },
        // {
        //     title: "Team",
        //     path: "/#team",
        //     show : !!businessData?.team?.length && true
        // }
    ]
    return (
        <div className="overflow-hidden template-4"> 
            <SEO data={seoData} />
            <NavbarComponent {...rest} setOpenRequestMeeting={setOpenRequestMeeting} data={businessData} navigations={headerNavigations}/>
	        <RequestMeetingModal setOpenRequestMeeting={setOpenRequestMeeting} openRequestMeeting={openRequestMeeting} urlHubspot={process.env.GATSBY_HUBSPOT_MEETING_URL}/>
            <main>
                <div style={{maxWidth: '1366px'}} className="mx-auto">
                    {children}
                </div>
            </main>
            <FooterComponent {...rest} classNames="grid grid-cols-1 xl:block px-20px pb-30px md-1:py-12 mx-auto sm:px-6 md-1:pt-0 md-1:pb-7
            xl:py-16 md-1:px-10 xl:pr-98px xl:pl-98px 3xl:pl-390px 3xl:pr-390px xs:text-center xl:py-0px" data={businessData}/>
        </div>
    );
};

export default Layout;