import React, { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuAlt1Icon, XIcon, MailIcon } from '@heroicons/react/outline';
import data from '@data/template-2/navigations.json';
import iconSearchHeader from '@assets/icons/search-header.svg';
import { nanoid } from 'nanoid';
import Img from 'gatsby-image';
import { Button } from '@tradesorg-components/component-library-waas-templates.base-button';
import styles from './index.module.scss';
import { InputTemplate } from '@tradesorg-components/component-library-waas-templates.base-input-template';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { PhoneIcon } from '@heroicons/react/solid';
import StyledLogo from '@components/commons/styled-logo'


const ItemsMenuLeft = ({ navigations, setOpen, setOpenRequestQuote }) => {

    return (
        <>
            <Popover.Group
                as="nav"
                className="flex xs:space-x-0 xl:space-y-0 xs:space-y-5 xl:space-x-10 xl:flex xs:grid grid-cols-1 w-full md-1:w-1/2 xl:w-auto"
            >
                {navigations &&
                    Array.isArray(navigations) &&
                    navigations.map((item) => {
                        if(item.show){
                            return (
                                <AnchorLink
                                    key={nanoid()}
                                    to={item.path}
                                    className="text-normal md-1:text-2xl xl:text-sm not-italic font-semibold xl:font-normal
                                    tracking-normal text-left text-gray-900 leading-loose xl:leading-4 hover:text-gray-500"
                                    onClick={() => setOpen && setOpen(false)}
                                >
                                    {item.title}
                                </AnchorLink>
                            );
                        }

                    })}
                    <AnchorLink to="/#contactUs">
                <Button

                    rounded
                    className="h-14 mt-6 text-white bg-purple px-8 py-4 rounded text-sm font-semibold block md-1:hidden"
                >
                    Contact Us
                </Button>
                </AnchorLink>
            </Popover.Group>
        </>
    );
};
const IconSearch = () => {
    return (
        <div className="mx-6 cursor-pointer">
            <img src={iconSearchHeader} alt="icon-search" />
        </div>
    );
};

const ItemsMenuRight = ({ email, phone, singleLineAddress, setOpenRequestQuote }) => {
    return (
        <div className="hidden md-1:block border-l xl:border-0">
            <div className="hidden xl:flex items-center">

                <AnchorLink to="/#contact">
                <Button

                    rounded
                    className="h-14 text-white bg-purple px-8 py-4 rounded text-sm font-semibold"
                >
                    Contact Us
                </Button>
                </AnchorLink>
            </div>
            <div className="grid xl:hidden items-center pl-10">
                <div className="items-center text-left">
                    <div className="text-xs leading-7 h-6 text-gray-400">GET IN TOUCH</div>
                    <div className="font-semibold leading-7 h-6 text-2xl mb-7">Contact Us</div>
                    <p className="mt-3 text-normal not-italic tracking-normal leading-4 mb-4">
                        { phone }
                    </p>
                    <p className="mt-3 text-normal not-italic tracking-normal leading-4 mb-4">
                        { email }
                    </p>
                    <p className="mt-3 text-normal not-italic tracking-normal leading-4">
                        { singleLineAddress }
                    </p>
                </div>
                <AnchorLink to="/#contact">
                <Button

                    rounded
                    className="h-14 mt-7 text-white bg-purple px-8 py-4 rounded text-sm font-semibold"
                >
                    Contact Us
                </Button>
                </AnchorLink>
            </div>
        </div>
    );
};

const NavbarComponent = ({ shadow = true, setOpenRequestQuote, data,navigations, ...props }) => {

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');

    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    const [scrolled,setScrolled] = useState(false);

    const handleScroll = () => {
        if(typeof window != "undefined"){
            const offset = window.scrollY;
            if(offset > 150 ) {
                setScrolled(true);
            }
            else {
                setScrolled(false);
            }
        }



    }

    useEffect(() => {
        if(typeof window != "undefined"){
            window.addEventListener('scroll', handleScroll)
        }

    })

    return (
        <Popover className={`navbar bg-transparent ${scrolled && `scrolled` }`} {...props}>
            <>
                <div
                    className={`absolute inset-0 z-10 shadow-md pointer-events-none`}
                    aria-hidden="true"
                />
                <div className="relative z-20">
                    <div className="max-w-screen-2xl mx-auto flex items-center justify-between h-auto sm:px-6  xs:px-5 md-1:px-8 xl:py-29px xs:py-32px sm:py-45px xl:pl-98px xl:pr-98px md:justify-between">
                        <StyledLogo logo={data.logo} />

                        <div className="ml-6 flex justify-end xl:hidden">
                            <div className=" -mr-2 mb-1 flex ">
                                {data?.phone ? <a href={`tel:${data?.phone}`}>
                                    <PhoneIcon className="h-8 w-8 mr-2 p-0.5" />
                                </a>: <></>}

                                {data?.email ? <a href={`mailto:${data?.email}`}>
                                    <MailIcon className="h-8 w-8" />
                                </a>: <></>}
                            </div>
                        </div>

                        <div className="flex justify-end xl:hidden">
                            <div onClick={() => setOpen(true)} className="-my-2 -mr-2 xl:hidden">
                                <Popover.Button
                                    className={`inline-flex items-center justify-center text-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-inset`}
                                >
                                    <span className="sr-only">Open menu</span>
                                    <MenuAlt1Icon
                                        className="w-8 h-8 text-black"
                                        aria-hidden="true"
                                    />
                                </Popover.Button>
                            </div>
                        </div>

                        <div className="hidden xl:flex-1 xl:flex xl:items-center xl:justify-between">
                            <ItemsMenuLeft
                                navigations={navigations}
                                setOpenRequestQuote={setOpenRequestQuote}
                            />
                            <ItemsMenuRight
                                {...data}
                                setOpenRequestQuote={setOpenRequestQuote}
                            />
                        </div>
                    </div>
                </div>

                <Transition
                    show={open}
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        static
                        className="absolute h-screen md-1:h-auto top-0 inset-x-0 p-2 transition transform origin-top-right xl:hidden z-20 xs:p-0"
                    >
                        <div className="h-full md-1:h-auto rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white xl:divide-y-2 xl:divide-gray-50">
                            <div className="px-5 md-1:px-10 py-6 xl:px-5 space-y-6">
                                <div className="flex items-center justify-between">
                                    <StyledLogo logo={props.logo} />
                                    <div onClick={() => setOpen(false)} className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            <InputTemplate
                                classNames={{ wrapper: 'mx-5 md-1:mx-10' }}
                                rounded={true}
                                name="search"
                                id="search"
                                placeholder="Search"
                                onChange={handleChange}
                                value={search}
                                LeftComponent={
                                    <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.8779 18.6996L14.0681 12.8897C15.17 11.5293 15.8334 9.79975 15.8334 7.9167C15.8334 3.55145 12.2819 0 7.91666 0C3.55141 0 0 3.55145 0 7.9167C0 12.2819 3.55145 15.8334 7.9167 15.8334C9.79975 15.8334 11.5293 15.17 12.8897 14.0681L18.6996 19.878C18.8623 20.0407 19.1261 20.0407 19.2889 19.878L19.878 19.2888C20.0407 19.1261 20.0407 18.8623 19.8779 18.6996ZM7.9167 14.1667C4.47024 14.1667 1.66668 11.3631 1.66668 7.9167C1.66668 4.47024 4.47024 1.66668 7.9167 1.66668C11.3631 1.66668 14.1667 4.47024 14.1667 7.9167C14.1667 11.3631 11.3631 14.1667 7.9167 14.1667Z"
                                            fill="#6e4bd9"
                                        />
                                    </svg>
                                }
                            />
                            <div className="pt-11 pb-14 px-5 md-1:px-10 xl:py-6 xl:px-5 space-y-6">
                                <div className="flex justify-between">
                                    <ItemsMenuLeft
                                        navigations={navigations}
                                        setOpen={setOpen}
                                        setOpenRequestQuote={setOpenRequestQuote}
                                    />
                                    <div className={`hidden xl:block ${styles.rectangle}`} />
                                    <ItemsMenuRight {...data} setOpenRequestQuote={setOpenRequestQuote} />
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>
        </Popover>
    );
};

export default NavbarComponent;
